.resume {
    justify-content: center;
}

.resume img {
    display: block;
    margin: auto;
    cursor: pointer;
}

.downloadText {
    text-align: center;
}